
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  domain: "https://allianpay.com",
  apiUrl: 'https://allianpay.com:3030',
  siteUrl: 'https://allianpay.com', 
  socketUrl: 'https://allianpay.com:9001',
  googleapiKey: "AIzaSyDrjtoIPgvVT7oQ8IQ_ZLLbjKEatmCFyBI",
  googleloginClientid: "996339786549-37s386mko1e86vjbgragtv583lbi9s2r.apps.googleusercontent.com",
  facebookloginClientid: "2550731744938622",
  firebase: {
    apiKey: "AIzaSyDrjtoIPgvVT7oQ8IQ_ZLLbjKEatmCFyBI",
    authDomain: "hstaxiuserapp.firebaseapp.com",
    databaseURL: "https://hstaxiuserapp.firebaseio.com",
    projectId: "hstaxiuserapp",
    storageBucket: "hstaxiuserapp.appspot.com",
    messagingSenderId: "996339786549",
    appId: "1:996339786549:web:c179a245621e447f"
  }
};
