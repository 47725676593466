import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireModule } from "@angular/fire";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule, Http } from '@angular/http';
import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import { environment } from "../environments/environment";
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { FormsModule }   from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as $ from "jquery";

/* JWT AUTHENTICATION */
import { JwtModule } from "@auth0/angular-jwt";

export function getToken() {
  return localStorage.getItem("authtoken");
}

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      environment.googleloginClientid
    )
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.facebookloginClientid)
  }
]);

export function provideConfig() {
  return config;
}

import { NgxSocialButtonModule, SocialServiceConfig } from "ngx-social-button";

// Configs
export function getAuthServiceConfigs() {
  let config = new SocialServiceConfig().addFacebook(environment.facebookloginClientid);
  return config;
}

/* TRANSLATIONS */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/* Services */
import { AuthenticationService } from "./service/authentication.service";
import { UserService } from "./service/user.service";
import { DriverService } from "./service/driver.service";
import { VehicleService } from "./service/vehicle.service";
import { RidesService } from "./service/rides.service";
import { PageService } from "./service/page.service";
import { FaqService } from "./service/faq.service";
import { HomeService } from "./service/home.service";
import { BannerService } from "./service/banner.service";
import { SettlementService } from "./service/settlement.service";
import { MessagingService } from "./service/messaging.service";
import { AdminguardGuard } from "./guards/adminguard.guard";
import { SocketService } from "./service/socket.service";
import { TxnService } from "./service/txn.service";

import { SwiperModule } from "ngx-swiper-wrapper";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { AsyncPipe } from "../../node_modules/@angular/common";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: 1,
  navigation: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    SwiperModule,
    HttpClientModule,
    HttpModule,
    NgxSocialButtonModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      progressBar: true,
      autoDismiss:true
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        whitelistedDomains: [""]
      }
    }),
    SocialLoginModule,
    NgbModule.forRoot(),
    TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: SocialServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    AdminguardGuard,
    AuthenticationService,
    PageService,
    FaqService,
    HomeService,
    BannerService,
    UserService,
    DriverService,
    VehicleService,
    RidesService,
    SettlementService,
    MessagingService,
    SocketService,
    AsyncPipe,
    TxnService
  ],
  exports: [

    TranslateModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
