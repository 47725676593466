import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";
const API_URL = environment.apiUrl + "/user";
@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}
  getPage(id) {
    return this.http.get(API_URL+"/"+id).pipe(map(res => res));
  }
  updateSetting(id,status) {
    return this.http.get(API_URL+"/updateSetting/"+id+"/"+status).pipe(map(res => res));
  }
  deactiveUser(formdata,id) {
    return this.http
      .post(API_URL + "/deactive/" + id, formdata)
      .pipe(map(res => res));
  }
  activeUser(id) {
    return this.http.post(API_URL + "/active/" + id, id).pipe(map(res => res));
  }
   resendotp(id) {
    return this.http.get(API_URL + "/resendotp/" + id).pipe(map(res => res));
  }
  generatepassword(id) {
    console.log("generatepassword");
    return this.http.get(API_URL + "/generatepassword/" + id).pipe(map(res => res));
  }
   resetpassword(formdata) {
    return this.http.post(API_URL + "/resetpassword/",formdata).pipe(map(res => res));
  }
  approvedoc(formdata) {
    console.log("approve doc calling");
    return this.http.post(API_URL + "/approvedoc/",formdata).pipe(map(res => res));
  }
  canceldoc(formdata) {
    console.log("cancel doc calling");
    return this.http.post(API_URL + "/canceldoc/",formdata).pipe(map(res => res));
  }
   verifydocument(formdata,userid) {
    return this.http.post(API_URL + "/verifydocument/" + userid,formdata).pipe(map(res => res));
  }
  addMoney(formdata,userid) {
    return this.http.post(API_URL + "/addmoney/" + userid, formdata).pipe(map(res => res));
  }
  getInfo(id) {
    return this.http.get(API_URL + "/info/" + id).pipe(map(res => res));
  }
  deleteuser_getInfo(id) {
    return this.http.get(API_URL + "/delete_user_info/" + id).pipe(map(res => res));
  }
  getcount() {
    return this.http.get(API_URL + "/wcount/").pipe(map(res => res));
  }
  getcount1() {
    return this.http.get(API_URL + "/hcount/").pipe(map(res => res));
  }
  getcount2() {
    return this.http.get(API_URL + "/rcount/").pipe(map(res => res));
  }
  getLatestuser() {
    return this.http.get(API_URL + "/latestUser/").pipe(map(res => res));
  }
  getiosuser() {
    return this.http.get(API_URL + "/iosUser/").pipe(map(res => res));
  }
  getandoriduser() {
    return this.http.get(API_URL + "/andoridUser/").pipe(map(res => res));
  }
  getwebuser() {
    return this.http.get(API_URL + "/webUser/").pipe(map(res => res));
  }
 

  sendNotification(Form) {
    const obj = {
      message: Form.message,
      user: Form.user 
    };
    return this.http.post(API_URL + "/pushNotify", obj).pipe(map(res => res));
  }
  edit(id) {
    const uri = API_URL + '/edit/' + id;
    return this.http.get(uri).pipe(
      map(res => {
        return res;
      })
    );
  }

  updatePage(formData, id) {
    const uri = API_URL + '/update/' + id;
    console.log(formData);
    return this.http.post(uri, formData).pipe(
     // catchError(this._errorHandler),
      map((res: Response) => res)
    );
  }

}
