import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl + '/txns';
@Injectable()
export class TxnService {
  constructor(private http: HttpClient) { }
  getPage(usertype) {
    return this
      .http
      .get(API_URL + '/txndetail/' + usertype).pipe(
        map(res => res));
  }
  dashboard(country,type) {
  console.log('test@@@@')
    const obj = {
      country: country,
      type: type
    };
    return this
      .http
      .post(API_URL + '/dashboard/',obj).pipe(
        map(res => res));
  }
  getusers(usertype,country,city) {
    const obj = {
      usertype: usertype,
      country: country,
      city:city
    };
    return this
      .http
      .post(API_URL + '/getusers',obj).pipe(
        map(res => res));
  }
  getInfo(id) {
    return this
      .http
      .get(API_URL + '/info/' + id).pipe(
        map(res => res));
  }
  
  
  getDriverRideCount(id) {
    return this
      .http
      .get(API_URL + '/getDriverRideCount/' + id).pipe(
        map(res => res));
  }
  getcount() {
    return this
      .http
      .get(API_URL + '/totalCount/').pipe(
        map(res => res));
  }
  getcurrentMonthride() {
    return this
      .http
      .get(API_URL + '/currentMonth/').pipe(
        map(res => res));
  }
  getLatestride() {
    return this
      .http
      .get(API_URL + '/latestRide/').pipe(
        map(res => res));
  }
  getCancelCount() {
    return this
      .http
      .get(API_URL + '/cancel/').pipe(
        map(res => res));
  }
  getCompletedCount() {
    return this
      .http
      .get(API_URL + '/completed/').pipe(
        map(res => res));
  }
  rideCounts() {
    return this
      .http
      .get(API_URL + '/rideCounts/').pipe(
        map(res => res));
  }
  getOnthewayCount() {
    return this
      .http
      .get(API_URL + '/ontheway/').pipe(
        map(res => res));
  }
  getScheduledCount() {
    return this
      .http
      .get(API_URL + '/scheduled/').pipe(
        map(res => res));
  }
  getAcceptedCount() {
    return this
      .http
      .get(API_URL + '/accepted/').pipe(
        map(res => res));
  }
  getNotAcceptCount() {
    return this
      .http
      .get(API_URL + '/ridenotaccepted/').pipe(
        map(res => res));
  }
  gettodayCount() {
    return this
      .http
      .get(API_URL + '/currentRecords/').pipe(
        map(res => res));
  }

  getridehistory(formData: any) {
    const uri = API_URL + '/ridehistory';
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }

  transactiondata() {
    return this
      .http
      .get(API_URL + '/transactionRecords/').pipe(
        map(res => res));
  }
  charttype(type,country) {
    return this
      .http
      .get(API_URL + '/transactiontype/'+type+'/'+country).pipe(
        map(res => res));
  }

}
