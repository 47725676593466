import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "app/service/authentication.service";
import { WebappService } from "app/service/webapp.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class AdminguardGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private webappService: WebappService,
    private router: Router,
    private jwtHelperService: JwtHelperService,
    public toastr: ToastrService,
    private translate: TranslateService,
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const expectedRole = next.data.expectedRole;
    const token = localStorage.getItem("authtoken");
    let tokenPayLoad = this.jwtHelperService.decodeToken(token);

    if (this.authService.loggedIn()) {
      
      if(tokenPayLoad.data.role=='wholesaler' || tokenPayLoad.data.role=='retailer' || tokenPayLoad.data.role=='halfwholesaler'){
        tokenPayLoad.data.role='user';
        this.webappService.profiledetails(tokenPayLoad.data._id).subscribe(res => {
          
          var res_obj:Object = res;
          console.log('ressss');
          console.log(res);console.log('ressss');
          if(res_obj['userstatus'] == 'waiting') {
            if(res_obj['doc'] == 'cancel' || res_obj['idcard'] == 'cancel'){
              this.toastr.warning(this.translate.instant("Please complete the document(s) that were rejected."));
              this.router.navigate(["verifydocs"]);
              return false;
            }if(res_obj['doc'] == 'approved' && res_obj['idcard'] == 'approved'){
              this.toastr.warning(this.translate.instant("Your account is being activated."));
              this.router.navigate(["verifydocs"]);
              return false;
            }else if(res_obj['userstatus'] == 'waiting') {
              this.toastr.warning(this.translate.instant("Your documents are being validated, which can take between 3 and 7 working days."));
              this.router.navigate(["verifydocs"]);
              return false;
              
            }else {
              if(this.router.url == '/verifydocs') {
                this.toastr.success(this.translate.instant("Your account activated by admin"));
                this.router.navigate(["dashboard"]);
                return false;
              }
            }
          }else{
            if(this.router.url == '/verifydocs') {
              this.toastr.success(this.translate.instant("Your account activated by admin"));
              this.router.navigate(["dashboard"]);
              return false;
            }
          }
          
        });
      }
      console.log("new token="+tokenPayLoad.role)
      
      if (tokenPayLoad.data.role.trim() !== expectedRole.trim()) {
        console.log('expectedRole');
        console.log(expectedRole);
        
        if (expectedRole === "admin") {
          /* console.log("Admin not allowed"); */
          this.router.navigate(["admin"]);
          return false;
        } else {
          /* console.log("User not allowed"); */
          
          this.router.navigate(["signin"]);
          return false;
        }
        
        
      }
      return true;
    } else {
      /* console.log('The Role of user is ' + expectedRole); */
      if (expectedRole === "admin") {
        /* console.log("Admin not allowed"); */
        this.router.navigate(["admin"]);
        return false;
      } else {
        /* console.log("User not allowed"); */
        this.router.navigate(["signin"]);
        return false;
      }
    }
  }
}
