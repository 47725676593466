import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/service/authentication.service';
import { PageService } from 'app/service/page.service';
import { FaqService } from 'app/service/faq.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-frontfooter',
  templateUrl: './frontfooter.component.html',
  styleUrls: ['./frontfooter.component.scss']
})
export class FrontfooterComponent implements OnInit {
  users: any;
  pages: any;
  privacy: any;
  links: any;
  helpContent: any;
  faqCount: any;
  constructor(private authService: AuthenticationService, private service: PageService,
    private faq: FaqService,private router: Router) { }

  ngOnInit() {
    this.authService.getUserinfo().subscribe(res => {
      this.users = res;

    });

    this.service.getPage().subscribe(res => {
      this.links = res;
    });

    this.service.getTerms().subscribe(res => {
      this.pages = res;
    });

    this.service.getPrivacy().subscribe(res => {
      this.privacy = res;
    });

    this.service.getUserHelpcontent().subscribe(res => {
      this.helpContent = res;
    });
    this.faq.getCount().subscribe(res => {
      this.faqCount = res;
    });
  }

  hyphenateUrlParams(str: string) {
    let urlParams = str.split(' ').join('-');
    return urlParams.replace(/[^a-zA-Z0-9-]/g, '');
  }

}
