import { Component,HostListener, OnInit } from "@angular/core";
import { jsonpFactory } from "@angular/http/src/http_module";
import { MessagingService } from "./service/messaging.service";
import { WebappService } from "./service/webapp.service";
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import * as  socketIo from 'socket.io-client';
import { Socket } from "socket.io-client";
import { environment } from "environments/environment";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
  message;
  profilelog;
  profileid;
  checkuserstarts: any;
  modalReference: any;
  socket:any;
  loggeddata: object;
  // template:any;
  constructor(private messagingService: MessagingService,
    private modalService: NgbModal,
    private translate: TranslateService,
    public toastr: ToastrService,
    private router: Router,
    private apiservice: WebappService,) {}

  ngOnInit() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;
    this.checkuserstarts = 1;
    const socketUrl = environment.socketUrl;
    
    console.log(socketUrl);

    this.socket = socketIo(socketUrl);

    this.socket.on('user_status',(data)=>{


      console.log(data.useris);
      if(data.useris == 'inactive')
           {

      let lang=this.translate.instant("Sorry! Your account deactivated by administrator");
      this.toastr.error(lang);
      
      this.profilelog = JSON.parse(localStorage.getItem("user"));
      this.profileid = this.profilelog.id;
      if (this.profileid) {
        this.apiservice
          .webSigninout({ user_id: this.profileid })
          .subscribe(data => {});
      }
      localStorage.clear();   
   
      this.router.navigate(["/signin"]);

           }
           else{
             console.log('user was currenty active');
            return false;
           }
    })
  }

  @HostListener('click') 
  onClick() {
    let user = localStorage.getItem("user");
    if(user){
      var user_data = JSON.parse(user);
       console.log("AppComponent ts file"+user_data);
      this.socket.emit('hello',{
        "user_email": user_data.email,
        "user_role":  user_data.role
        })   
      }        
  }



}
