import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { environment } from "../../environments/environment";

import { environment } from "environments/environment";

const API_URL = environment.apiUrl + "/api";
const DRIVER_API_URL = environment.apiUrl + "/driverapi";

@Injectable({
  providedIn: "root"
})
export class WebappService {
  constructor(private http: HttpClient) { }

  accountsignin(formData: any) {
    const uri = API_URL + "/signin";
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }

  checckactivestatus(formData:any){
    console.log(formData)
    const uri = API_URL + "/checckactivestatus";
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );

  }
   verifysecuritycode(formData:any){
    console.log(formData)
    const uri = API_URL + "/securitycode";
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );

  }


  securitycode(formData:any){
    console.log("securitycode="+formData)
    const uri = API_URL + "/securitycode";
     const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        //Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData,httpOptions).pipe(
      map(res => {
        return res;
      })
    );


  }

  accountsignup(formData: any) {
    const uri = API_URL + "/signup";
    console.log('formData',formData)  
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }
  verifydocuments(formData: any) {
    const uri = API_URL + "/verifydocuments";
    console.log('formData verifydocuments',formData)  
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }
  //  verifydocuments(formData: any) {
  //   const uri = API_URL + "/verifydocuments";
  //    const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       Authorization: localStorage.getItem("authtoken")
  //     })
  //   };
  //   console.log('formData',formData)  
  //   return this.http.post(uri, formData,httpOptions).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );
  // }
  changesecuritycode(id) {
    const uri = API_URL + "/changesecuritycode/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.get(uri, httpOptions).pipe(
      map(res => {
      console.log(res);
        return res;
      })
    );
  }

  profiledetails(id) {
    const uri = API_URL + "/profile/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.get(uri, httpOptions).pipe(
      map(res => {
      console.log('user_profileeeeeee2');
        
        return res;
      })
    );
  }


subaccountdetails(id) {
    const uri = API_URL + "/managesubaccount/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.get(uri, httpOptions).pipe(
      map(res => {
      console.log(res);
        return res;
      })
    );
  }
  subaccountdetail(id) {
    const uri = API_URL + "/viewsubaccount/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.get(uri, httpOptions).pipe(
      map(res => {
      console.log(res);
        return res;
      })
    );
  }
  

  profileupdate(formData: any) {
    const uri = API_URL + "/updateprofile/";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(map(res => res));
  }

  updateProfilePic(formData: any) {
    const uri = API_URL + "/uploadprofileimage";
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  changepassword(formData: any) {
    const uri = API_URL + "/updatepassword/";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(map(res => res));
  }
  updatestatus(formData: any) {
    const uri = API_URL + "/updatestatus/";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(map(res => res));
  }

  changephoneno(formData: any) {
    const uri = API_URL + "/updatephonenumber/";
    return this.http.post(uri, formData).pipe(map(res => res));
  }

  forgotpassword(formData: any) {
    const uri = API_URL + "/resetpassword/";
    return this.http.post(uri, formData).pipe(map(res => res));
  }

  braintreetoken(formData: any) {
    const uri = API_URL + "/getclienttoken";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  creditwallet(formData: any) {
    const uri = API_URL + "/addmoney";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

   walletrecharge(formData: any) {
    const uri = API_URL + "/walletrecharge";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }
  wallethistory(formData: any) {
    console.log("wallethistory called");
    const uri = API_URL + "/wallethistory";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }
dashboard(formData: any) {
    console.log("dashboard called");
    const uri = API_URL + "/dashboard";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }
  // wallethistory(id) {
  //   const uri = API_URL + "/wallethistory/" + id;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       Authorization: localStorage.getItem("authtoken")
  //     })
  //   };
  //   return this.http.get(uri, httpOptions).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );
  // }

  accountexists(formData: any) {
    const uri = API_URL + "/checkaccountexists";
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }
   createsubaccount(formData: any) {
    console.log("createsubaccount ts file");
    const uri = API_URL + "/addsubaccount";
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }
 updatesubaccount(formData: any) {
    console.log("updatesubaccount ts file");
    const uri = API_URL + "/updatesubaccount";
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }
  showallcategories() {
    const uri = API_URL + "/allcategories";
    return this.http.get(uri).pipe(
      map(res => {
        return res;
      })
    );
  }

  shownearbycategories(formData: any) {
    const uri = API_URL + "/grabrides";
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }

  getnotification(id) {
    const uri = API_URL + "/notifications/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.get(uri, httpOptions).pipe(map(res => res));
  }
  getcurrency(id) {
    console.log("getcurrency called");
    const uri = API_URL + "/getcurrency/" + id;
    return this.http.get(uri).pipe(
      map(res => {
        return res;
      })
    );
    }
  requestride(formData: any) {
    const uri = API_URL + "/requestride";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  confirmride(formData: any) {
    const uri = API_URL + "/confirmride";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  lastrequestedride(id) {
    const uri = API_URL + "/lastridedetails/" + id;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.get(uri, httpOptions).pipe(map(res => res));
  }

  ridehistory(formData: any) {
    const uri = API_URL + "/ridehistory";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  cancelride(formData: any) {
    const uri = API_URL + "/cancelride";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  rideinprogress(formData: any) {
    const uri = API_URL + "/ongoingrideindetail";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  ridecompleted(formData: any) {
    const uri = API_URL + "/completeridedetails";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  rideincompleted(formData: any) {
    const uri = API_URL + "/incompleterideindetail";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  reviewaride(formData: any) {
    const uri = API_URL + "/review";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  ridedetails(formData: any) {
    const uri = API_URL + "/ridedetails";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  cashpayment(formData: any) {
    const uri = API_URL + "/paybycash";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  cardpayment(formData: any) {
    const uri = API_URL + "/paybycard";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  appconfig() {
    const uri = API_URL + "/admindatas";
    return this.http.get(uri).pipe(
      map(res => {
        return res;
      })
    );
  }

  deviceSignin(formData) {
    const uri = API_URL + "/pushsignin";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  nodriverfound(formData) {
    const uri = API_URL + "/nooneaccept";
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.post(uri, formData, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  webSignin(formData) {
    const uri = API_URL + "/appsignin";
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }

  webSigninout(formData) {
    const uri = API_URL + "/appsignout";
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }
   customerdetails(id,userid,lang) {
    console.log("customerdetails="+id);
    const uri = API_URL + "/customerdetails/" + id + '/'+userid+'/'+lang;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("authtoken")
      })
    };
    return this.http.get(uri, httpOptions).pipe(
      map(res => {
        return res;
      })
    );
  }

  otpverification(formData: any) {
    const uri = API_URL + "/otpverification";
    console.log('formData',formData)  
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }
  resendotp(formData: any) {
    const uri = API_URL + "/resendotp";
    console.log('formData',formData)  
    return this.http.post(uri, formData).pipe(
      map(res => {
        return res;
      })
    );
  }
  // phase 2 custom works
  //  setsecuritycode(formData: any) {
  //   console.log("setsecuritycode called ts"+JSON.stringify(formData,null,4));
  //   const uri = API_URL + "/setsecuritycode";
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       Authorization: localStorage.getItem("authtoken")
  //     })
  //   };
  //   return this.http.post(uri, formData, httpOptions).pipe(map(res => res));
  // }

  // setsecuritycode(formData: any) {
  //   const uri = API_URL + "/setsecuritycode";
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       "Content-Type": "application/json",
  //       Authorization: localStorage.getItem("authtoken")
  //     })
  //   };
  //   return this.http.post(uri, formData, httpOptions).pipe(
  //     map(res => {
  //       return res;
  //     })
  //   );
  // }






}
