import { Component, HostBinding, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/service/authentication.service';
@Component({
    // moduleId: module.id,
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
    user: any;
    constructor(private authService: AuthenticationService) { }
    ngOnInit() {
        this.authService.getUserinfo().subscribe(res => {
            this.user = res;
        });
    }
}